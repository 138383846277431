import axios from "../api/axios";
import { getInsurerAndAddOnsListURL } from "../api";

export const getInsurersList =
  ({ product, policyType }) =>
  async (dispatch) => {
    try {
      let response = await axios.get(getInsurerAndAddOnsListURL, {
        params: { product, policyType },
      });
      if (response.status === 200) {
        dispatch({ type: "GET_INSURER_LIST", data: response.data.data });
      }
    } catch (error) {
      throw error.message;
    }
  };
