import { TextField, Typography, InputAdornment } from "@mui/material";
import React from "react";

function TextInput({
  label,
  isMandatory,
  icon,
  iconPosition = "start",
  ...rest
}) {
  return (
    <>
      <Typography variant="caption">
        {label} {isMandatory && "*"}
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        sx={{
          border: "1px solid #8B8B8B",
          backgroundColor: "#fff",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          // Error state
          ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #F44336",
          },
          borderRadius: 2,
        }}
        InputProps={{
          startAdornment:
            icon && iconPosition === "start" ? (
              <InputAdornment
                position="start"
                sx={{ width: "30px", height: "30px" }}
              >
                <img
                  src={icon}
                  alt="icon"
                  style={{ width: "50px", height: "50px" }}
                />
              </InputAdornment>
            ) : null,
          endAdornment:
            icon && iconPosition === "end" ? (
              <InputAdornment
                position="end"
                sx={{ width: "30px", height: "30px" }}
              >
                <img
                  src={icon}
                  alt="icon"
                  style={{ width: "26px", height: "26px" }}
                />
              </InputAdornment>
            ) : null,
        }}
        size="small"
        {...rest}
      />
    </>
  );
}

export default TextInput;
