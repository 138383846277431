import {
  Box,
  Button,
  CircularProgress,
  Stack,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../generic/Heading/Heading";
import CustomToggleButton from "../generic/Button/CustomToggleButton";
import DropDown from "../generic/DropDown/DropDown";
import VerifyDocsMappingRequests from "../../pages/mappingRequests/VerifyDocsMappingRequests";
import { MappingRequestVerifyDocsAPI } from "../../store/action/mappingRequests";
import SuccessModal from "../generic/Modal";
import { message } from "../generic/SnakeAlert/SnakeAlert";


const VerifyPolicyModal = ({ handleClose }) => {
  const { mappingVerificationDetails, isLoadingDetails } = useSelector(
    (state) => state.mappingRequests,
  );
  const [insurerList, setInsurerList] = React.useState([]);
  const [docs, setDocs] = useState([]); // Initialize as an empty array
  const [formats, setFormats] = useState(null);
  const [insurer, setInsurer] = useState(() => "");
  const [product, setProduct] = useState("");
  const [verifyDocuments, setVerifyDocuments] = useState([]); // Initialize as an empty array
  const [openSuccess, setOpenSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleSubmit = () => {
    if (!formats) {
      message.error("Please select policy type");
      return;
    }
    if (!insurer) {
      message.error("Please select insurer");
      return;
    }
    if (!product) {
      message.error("Please select product");
      return;
    }
    const payload = {
      mappingId: mappingVerificationDetails?.mappingId,
      documentStatusList: verifyDocuments,
      insurerName: insurer,
      policyType: formats,
      product: product,
    };
    dispatch(MappingRequestVerifyDocsAPI(payload, setOpenSuccessModal));
  };
  useEffect(() => {
    if (mappingVerificationDetails?.extraData?.insurerList) {
      setInsurerList(mappingVerificationDetails.extraData.insurerList);
      if (mappingVerificationDetails.extraData.insurerName !== "")
        setInsurer(mappingVerificationDetails.extraData.insurerName);
    }
    if (mappingVerificationDetails?.extraData?.policyType) {
      setFormats(mappingVerificationDetails.extraData.policyType);
    }
    if (mappingVerificationDetails?.extraData?.product) {
      setProduct(mappingVerificationDetails.extraData.product);
    }
    if (mappingVerificationDetails?.documentData) {
      setDocs(mappingVerificationDetails.documentData);
    }
  }, [mappingVerificationDetails]);

  return (
    <>
      {openSuccess ? (
        <SuccessModal
          title={"Policy Verified Successfully"}
          subTitle={"Policy has been verified successfully"}
          shareButton={"Close"}
          btnDisabled={false}
          onClick={() => {
            setOpenSuccessModal(false);
            handleClose();
          }}
        />
      ) : isLoadingDetails ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Stack direction="column" spacing={3}>
            <Heading type={"withborder medium"} text={"Policy Type"} />
            <Stack direction="row" spacing={4}>
              <ToggleButtonGroup
                exclusive
                sx={{ display: "flex", gap: 4 }}
                value={formats}
                spacing={2}
                onChange={handleFormat}
                fullWidth
                // aria-label="text formatting"
              >
                <CustomToggleButton value="COMP">
                  Comprehensive
                </CustomToggleButton>
                <CustomToggleButton value="SAOD">SAOD</CustomToggleButton>
                <CustomToggleButton value="TP">Third Party</CustomToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Heading type={"withborder medium"} text={"Select Insurer"} />
            <DropDown
              value={insurer}
              onChange={(e) => setInsurer(e.target.value)}
              fullWidth
              height={60}
              placeholder="Select"
              data={insurerList}
            />
             <Heading type={"withborder medium"} text={"Select Product"} />
            <DropDown
              value={product}
              onChange={(e) =>
                setProduct(
                  e.target.value,
                )
              }
              fullWidth
              height={60}
              placeholder="Select"
              data={[
                "TW",
                "CAR",
                "GCV",
                "PCV",
                "BUS",
                "MISC_D",
              ]}
            />
            <Heading type={"withborder medium"} text={"Verify Documents"} />
            <VerifyDocsMappingRequests
              docs={docs}
              verifyDocuments={verifyDocuments}
              setVerifyDocuments={setVerifyDocuments}
            />
            <Box
              fullWidth
              display={"flex"}
              justifyItems={"end"}
              direction="row"
            >
              <Button
                variant="contained"
                sx={{
                  width: "110px",
                  borderRadius: "8px",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#0691FC" },
                  backgroundColor: "#0691FC",
                  textTransform: "none",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </div>
      )}
    </>
  );
};

export default VerifyPolicyModal;
