import axios from "../api/axios";
import { getSalesQuoteListURL } from "../api";
export const SALES_QUOTE_LOADING = "SALES_QUOTE_LOADING";
export const GET_SALES_QUOTE_LIST = "GET_SALES_QUOTE_LIST";
export const RESET_SALES_QUOTE = "RESET_SALES_QUOTE";

export const getSalesQuoteListAPI = (search) => async (dispatch) => {
  try {
    dispatch({ type: SALES_QUOTE_LOADING, data: true });
    let response = await axios.get(getSalesQuoteListURL, {
      params: search,
    });
    if (response.status === 200 && response.data && response.data.data) {
      dispatch({ type: GET_SALES_QUOTE_LIST, data: response.data.data });
    }
  } catch (error) {
    throw error.message;
  } finally {
    dispatch({ type: SALES_QUOTE_LOADING, data: false });
  }
};
