import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMappingListAPI,
  getMappingVerificationDetailsAPI,
  RESET_MAPPING_REQUESTS_LIST,
} from "../../store/action/mappingRequests";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MappingRequestTable from "../../components/Table/MappingRequestTable";
import VerifyPolicyModal from "../../components/modals/VerifyPolicyModal";
import MapPolicyModal from "../../components/modals/MapPolicyModal";
import DialogBox from "../../components/generic/DialogBox";
import Paginations from "../../components/generic/Pagination/Pagination";
import CustomTabing from "../../components/generic/Tabing/CustomTabing";
import { debounce, formatDate } from "../../utils/utils";

export default function MappingRequest() {
  const dispatch = useDispatch();
  let searchParams = {};

  const mappingList = useSelector(
    (state) => state.mappingRequests.mappingRequestsList,
  );
  const pagination = useSelector((state) => state.mappingRequests.pagination);
  const mappingBucket = useSelector(
    (state) => state.mappingRequests.mappingBucket,
  );

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [product, setProduct] = useState("");
  const [search, setSearch] = useState("");
  const [currentBucket, setCurrentBucket] = useState();
  const isLoading = useSelector(
    (state) => state.mappingRequests.isLoadingRequests,
  );

  const handlePageChange = (event, page) => {
    if (page > pagination?.totalPage) {
      return;
    }
    setCurrentPage(page);
  };
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    setCurrentBucket(newValue);
    setCurrentPage(1);
  };

  // debounce
  const debouncedMapping = useCallback(
    debounce((searchParams) => {
      dispatch(getMappingListAPI(searchParams));
    }, 500),
    [dispatch],
  );
  useEffect(() => {
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }
    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }
    if (search) {
      if (search.length < 3) return;
      searchParams["searchQuery"] = search;
    }
    if (product) {
      searchParams["product"] = product;
    }
    debouncedMapping(searchParams);
  }, [currentPage, currentBucket, filterByDate, search, product]);

  const handleSearch = (query) => {
    setSearch(query);
  };

  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setTicketId(null);
    setModalType(null);
  };
  const handleDialogOpen = (ticketId, type) => {
    setTicketId(ticketId);
    setModalType(type);
    setOpen(true);
    if (type === "VerifyPolicy" && ticketId)
      dispatch(getMappingVerificationDetailsAPI(ticketId));
  };
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_MAPPING_REQUESTS_LIST });
    };
  }, []);

  return (
    <main className="main">
      <Header title="Mapping Requests" />
      <header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Search
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            {/*eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="label">Filters :</label>
            <DropDown
              value={product}
              onChange={(e) =>
                setProduct(
                  e.target.value === "No Product" ? "" : e.target.value,
                )
              }
              width={145}
              placeholder="Product"
              data={["CAR", "TW", "GCV", "PCV", "BUS", "MISC_D", "No Product"]}
            />
            <div className="date-picker">
              <Flatpickr
                placeholder="Date Range"
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                  mode: "range",
                }}
                className="flat_picker_date"
                style={{ width: "200px", height: "41px" }}
                value={
                  filterByDate.startDate && filterByDate.endDate
                    ? [filterByDate.startDate, filterByDate.endDate]
                    : ""
                }
                onChange={handleDateChange}
              />
            </div>
          </Stack>
        </Stack>
      </header>

      <CustomTabing
        tabList={mappingBucket?.bucketList || []}
        value={mappingBucket?.curBucket}
        handleChange={handleTabChange}
      />

      <div className="list-wrapper">
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <MappingRequestTable
            rows={mappingList}
            handleDialogOpen={handleDialogOpen}
          />
        )}

        {open && modalType === "VerifyPolicy" && (
          <DialogBox
            title={
              modalType === "VerifyPolicy" ? (
                <Typography
                  variant="h6"
                  component={"div"}
                  sx={{ flexGrow: 1, color: "#09CE1D" }}
                  textAlign="center"
                >
                  Verify Policy
                </Typography>
              ) : (
                "Map Policy"
              )
            }
            open={open}
            setOpen={setOpen}
            children={
              <VerifyPolicyModal
                ticketId={ticketId}
                handleClose={handleClose}
              />
            }
          />
        )}
        {open && modalType === "MapPolicy" && (
          <MapPolicyModal
            handleClose={handleClose}
            open={open}
            mappingId={ticketId}
          />
        )}
      </div>
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </main>
  );
}
