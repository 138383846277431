import { Card, CardContent, Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as TotalMappingIcon } from "../../../assets/icons/TotalMappingIcon.svg";
import { ReactComponent as CarNew } from "../../../assets/images/NewQuoteRequest/Car.svg";
import { ReactComponent as Bike } from "../../../assets/images/NewQuoteRequest/Bike.svg";
import { ReactComponent as Bus } from "../../../assets/images/NewQuoteRequest/Bus.svg";

export default function DashboardMappingCard({ mappingDetails }) {
  return (
    <Card
      sx={{
        maxWidth: "342px",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "16px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          padding: "5px 1px",
          gap: "24px",
        }}
      >
        <Typography
          component={"div"}
          className="card-container"
          sx={{
            display: "flex,",
            height: "150px",
            gap: "20px",
          }}
        >
          <Typography
            component={"div"}
            className="card-upperhalf"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "5px",
              gap: "4px",
              width: "340px",
              height: "56px",
              borderBottom: "1px solid #F2F2F2 ",
              borderBottomColor: "grey",
            }}
          >
            <Typography
              component={"span"}
              style={{
                background: "#E4FDE9",
                borderRadius: "25px",
                width: "40px",
                height: "40px",
                // placeContent: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TotalMappingIcon />
            </Typography>

            <Typography
              component={"span"}
              className="heading"
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                alignItems: "center",
                // padding: "12px 16px",
                gap: "8px",
              }}
            >
              Total Mapping
            </Typography>
          </Typography>
          <Typography
            component={"div"}
            className="card-mid"
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "56px",
              borderBottom: "1px solid #F2F2F2 ",
              gap: "20px",
              padding: "12px 22px 12px 22px",
              alignItems: "center",
            }}
          >
            <Typography
              component={"div"}
              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "black",
                  lineHeight: "27px",
                }}
              >
                {mappingDetails?.totalMappingRaised}{" "}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#666666",
                }}
              >
                raised
              </Typography>
            </Typography>
            <span
              style={{
                fontWeight: "400",
                fontSize: "20px",
                lineHeight: "30px",
                color: "#666666",
              }}
            >
              /
            </span>
            <Typography
              component={"div"}
              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <Typography
                component={"div"}
                sx={{ fontWeight: "600", fontSize: "20px", color: "black" }}
              >
                {mappingDetails?.totalMappingMapped}{" "}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#666666",
                }}
              >
                mapped
              </Typography>
            </Typography>
          </Typography>
          <Typography
            component={"div"}
            className="card-bottom"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "50px",
              justifyContent: "space-between",
              border: "1px 0px 0x 0px",
              background: "linear-gradient(180deg, white 0%, #E8F3FC 100%)",
              marginTop: "12px",
            }}
          >
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "112px",
                height: "62px",
                padding: "2px 0px 8px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "44px",
                  width: "24px",
                  alignItems: "center",
                  height: "24px",
                  textAlign: "center",
                }}
              >
                <CarNew
                  color={"#B3B3B3 solid"}
                  width={"14px"}
                  height={"10px"}
                />
              </Typography>
              <Typography component={"span"} sx={{fontSize:"12px",fontWeight:"500"}}>
                {mappingDetails?.carMappingRaised} /{" "}
                {mappingDetails?.carMappingMapped}
              </Typography>
            </Typography>
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "112px",
                height: "62px",
                padding: "2px 0px 8px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "44px",
                  width: "24px",
                  alignItems: "center",
                  height: "24px",
                  textAlign: "center",
                }}
              >
                <Bike color={"#B3B3B3 solid"} width={"20px"} height={"15px"} />
              </Typography>
              <Typography component={"span"} sx={{fontSize:'12px',fontWeight:'500'}}>
                {mappingDetails?.twMappingRaised} /{" "}
                {mappingDetails?.twMappingMapped}
              </Typography>
            </Typography>
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "112px",
                height: "62px",
                padding: "2px 0px 8px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "44px",
                  width: "24px",
                  alignItems: "center",
                  height: "24px",
                  textAlign: "center",
                }}
              >
                <Bus color={"#B3B3B3 solid"} width={"20px"} height={"15px"} />
              </Typography>
              <Typography component={"span"} sx={{fontSize:'12px',fontWeight:'500'}}>
                {mappingDetails?.cvMappingRaised} /{" "}
                {mappingDetails?.cvMappingMapped}
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
}
