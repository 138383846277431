import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { ReactComponent as TopPerformingIcon } from "../../../assets/icons/TopPerformingIcon.svg";
import LinearProgressBar from "./LinearProgressBar";

export default function TopPerformingTable({ topPerformingPartners }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: "20px",
        maxHeight: "375px",
        overflowY: "auto",
      }}
    >
      <Typography
        component={"div"}
        sx={{
          padding: "6px 0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "2px solid #F2F2F2",
            width: "100%",
            justifyContent: "start",
            height: "64px",
          }}
        >
          <TopPerformingIcon />
          <Typography
            component={"span"}
            sx={{
              height: "22px",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#181818",
              marginLeft: "8px",
            }}
          >
            Top Performing Partners
          </Typography>
        </Typography>
      </Typography>

      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "12px",
                color: "#808080",
                lineHeight: "7px",
                fontWeight: "500",
                letterSpacing: "-0.02em",
              }}
            >
              NAME
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                color: "#808080",
                lineHeight: "7px",
                fontWeight: "500",
                letterSpacing: "-0.02em",
              }}
            >
              APE
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                color: "#808080",
                lineHeight: "7px",
                fontWeight: "500",
                letterSpacing: "-0.02em",
              }}
            >
              NOP
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                color: "#808080",
                lineHeight: "7px",
                fontWeight: "500",
                letterSpacing: "-0.02em",
              }}
            >
              PROGRESS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topPerformingPartners.map((partner) => (
            <TableRow
              key={partner.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "500",
                    color: "#181818",
                    lineHeight: "24px",
                    fontsize: "12px",
                    letterSpacing: "-2",
                  }}
                >
                  {partner.name}
                </Typography>
                {partner.hierarchy?.length > 0 && (
                  <Typography
                    variant="body2"
                    sx={{ color: "#808080", fontSize: "12px" }}
                  >
                    {partner.hierarchy.join(" > ")}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#181818",
                  lineHeight: "24px",
                  fontsize: "12px",
                  letterSpacing: "-2",
                }}
              >
                {partner.ape}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#181818",
                  lineHeight: "24px",
                  fontsize: "12px",
                  letterSpacing: "-2",
                }}
              >
                {partner.nop}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#181818",
                  lineHeight: "24px",
                  fontsize: "12px",
                  letterSpacing: "-2",
                }}
              >
                <LinearProgressBar progress={partner.progress} />{" "}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
