import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { productTypeImageObj, quoteStatesBGColor } from "../../helpers/enums";
import { formatDate } from "../../utils/utils";
import "./index.css";

const SalesQuoteTable = ({ rows }) => {
  const handleBGColor = (key) => {
    return quoteStatesBGColor[key] || "#FFBB0D";
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ height: "calc(100vh - 354px)" }}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          {rows?.length !== 0 ? (
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow
                    className={
                      row.quoteState === "DOCUMENT_REUPLOAD"
                        ? "mapping_table_row cursor-pointer"
                        : "mapping_table_row"
                    }
                    key={`${row}-${index}`}
                    sx={{
                      marginBottom: "16px",
                      background: "white",
                      height: "90px",
                    }}
                    rowSpacing={2}
                    // onClick={(e) =>
                    //   row.quoteState === "DOCUMENT_REUPLOAD" &&
                    //   // handleToToggleModal(e, "upoad_doc", row.quoteId)
                    // }
                  >
                    <TableCell align="left">
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div
                          style={{
                            padding: "16px",
                            borderRadius: "8px",
                            background: "#fafafa",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {productTypeImageObj[row?.product] || ""}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 120,
                              color: "#000",
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            {row?.registrationNumber}
                          </span>
                          <span
                            style={{
                              color: "#808080",
                              fontSize: 12,
                              fontWeight: "400",
                            }}
                          >
                            {row?.customerName}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          Partner
                        </span>
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.partnerId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          TicketID
                        </span>
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.quoteId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          background: handleBGColor(row?.quoteState),
                          borderRadius: 20,
                          color: "#fff",
                          padding: "6px 12px",
                          fontSize: 12,
                          cursor: "default",
                        }}
                      >
                        {row?.quoteStateName}
                      </span>
                    </TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      <span
                        className="date_content"
                        style={{
                          color: "#808080",
                          fontSize: 12,
                          fontWeight: "400",
                        }}
                      >
                        {row && formatDate(row.timestamp)}
                      </span>
                      {/* {row.quoteState === "DOCUMENT_REUPLOAD" && (
                        <div className="more_info">
                          <div className="btn_block">
                            <img src={Upload} alt="upload" />
                            <p className="mb-0 btn_title">Upload Docs</p>
                          </div>
                        </div>
                      )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Typography sx={{ my: 5 }}>No Data Found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SalesQuoteTable;
