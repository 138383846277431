import React from "react";
import "./Button.css";

const CustomButton = (props) => {
  const {
    buttonName,
    className,
    btnNameClass,
    handleClick,
    disabled,
    alignCenter,
    fullWidth,
    icon,
    ...rest
  } = props || {};
  return (
    <button
      className={
        fullWidth ? `button w-100 ${className}` : `button ${className} `
      }
      style={{
        alignItems: alignCenter ? "center" : "flex-start",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        width:"156px"
      }}
      onClick={handleClick}
      disabled={!!disabled}
      {...rest}
    >
      {icon && <span className="icon">{icon}</span>}
      <span className={btnNameClass ? btnNameClass : "btn_name"}>
        {buttonName || "SUBMIT"}
      </span>
    </button>
  );
};

export default CustomButton;
