import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { newQuoteRequestHeader } from "../../helpers/enums";
import "./index.css";
import { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TextInput from "../../components/generic/Input";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Upload from "../../assets/icons/Upload.svg";
import DropDown from "../../components/generic/DropDown/DropDown";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { SalesMappingRequest } from "../../store/action/salesMappingRequest";
import { useEffect } from "react";
import regIcon from "../../assets/images/RaiseRequest/RegNumber.svg";
import policyIcon from "../../assets/images/RaiseRequest/policynumber.svg";
import phoneIcon from "../../assets/images/RaiseRequest/phone.svg";
import busIcon from "../../assets/images/RaiseRequest/busName.svg";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import verified from "../../assets/Lottie/verified.json";
import { getSalesMappingListAPI } from "../../store/action/salesMapping";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DoneIcon } from "../../assets/icons/DoneIcon.svg"


export default function RaisePolicyMapping({ open, onClose, partnerId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeHeader, setActiveHeader] = useState(newQuoteRequestHeader[0]);
  const [selectedChip, setSelectedChip] = useState("new");
  const [veichleState, setVeichleState] = useState(["Vehicle is New"]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const initialData = {
    policyNumber: "",
    contactNumber: "",
    POLICY_COPY: null,
    vehicleType: "Vehicle is New",
    OTHERS: null,
    registrationNumber: "",
    vehicleOwner: "",
    partnerId: partnerId || "",
    PERMIT: null,
    vehicleSubClass: null,
  };
  const handleNavigateBtn = () => {
    onClose();
    dispatch(getSalesMappingListAPI({ curPage: 1 }));
    navigate("/app/sales/mapping-requests"); // Navigate to the desired route
  };

  //validations
  const schema = Yup.object().shape({
    registrationNumber:
      selectedChip === "old"
        ? Yup.string().required("Registration number is required")
        : Yup.string().required("RTO code is required"),
    policyNumber: Yup.string().required("Policy Number is required"),
    contactNumber: Yup.string()
      .nullable()
      .matches(/^\d{10}$/, "Contact Number must be exactly 10 digits")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value,
      ),

    vehicleOwner:
      activeHeader?.key === "BUS"
        ? Yup.string().required("Vehicle owner name is required")
        : Yup.string().nullable(),
    vehicleSubClass:
      activeHeader?.key === "BUS"
        ? Yup.string().required("Vehicle subtype is required")
        : Yup.string().nullable(),
    POLICY_COPY: Yup.mixed()
      .required("Policy Copy is required")
      .test("fileSize", "File size is too large", (value) =>
        value ? value.size <= 5000000 : true,
      )
      .test("fileType", "Unsupported File Format", (value) =>
        value ? ["application/pdf"].includes(value.type) : true,
      ),
    OTHERS: Yup.mixed()
      .test("fileSize", "File size is too large", (value) =>
        value ? value.size <= 5000000 : true,
      )
      .test("fileType", "Unsupported File Format", (value) =>
        value
          ? ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
          : true,
      )
      .nullable(), // Allows null values
    PERMIT:
      activeHeader?.key === "BUS"
        ? Yup.mixed()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) =>
              value ? value.size <= 5000000 : true,
            )
            .test("fileType", "Unsupported File Format", (value) =>
              value ? ["application/pdf"].includes(value.type) : true,
            )
        : Yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
    reValidateMode: "onChange",
    context: { activeHeader },
  });

  useEffect(() => {
    if (open) {
      reset(); // Reset form values when the modal opens
      setFormSubmitted(false); // Reset the form submission state
    }
  }, [open, reset]);

  const handleChipClick = (type) => {
    setSelectedChip(type);

    if (type !== "bus") {
      setValue("vehicleSubClass", null);
    }
  };
  const resetValues = (data) => {
    for (const key in data) {
      setValue(key, data[key]);
    }
  };

  useEffect(() => {
    // Update partnerId in the form when the prop changes
    if (partnerId) {
      setValue("partnerId", partnerId);
    }
  }, [partnerId, setValue]);

  const handleHeaderChange = (item) => {
    setActiveHeader(item);
    resetValues({
      ...initialData,
    });
  };

  useEffect(() => {
    setVeichleState(
      selectedChip === "new" ? ["Vehicle is New"] : ["Vehicle is Old"],
    );
  }, [selectedChip]);

  const onSubmit = async (data, reset) => {
    try {
      setLoading(true);
      setFormSubmitted(false);

      // Create FormData object
      const formData = new FormData();

      // Append form fields to FormData
      formData.append("policyNumber", data?.policyNumber);
      formData.append("contactNumber", data?.contactNumber);
      formData.append(
        "vehicleType",
        veichleState[0] === "Vehicle is New" ? "NEW" : "OLD",
        // "NEW",
      );
      formData.append("partnerId", data?.partnerId); // // Ensure partnerId is appended
      // Append partner ID
      if (data?.vehicleSubClass)
        formData.append("vehicleOwner", data?.vehicleOwner);
      if (data?.vehicleSubClass)
        formData.append("vehicleSubClass", data?.vehicleSubClass);
      formData.append("registrationNumber", data?.registrationNumber);
      formData.append("product", activeHeader?.key || ""); // Ensure key from activeHeader is used

      // Append files (if any)
      if (data.POLICY_COPY) {
        formData.append("POLICY_COPY", data?.POLICY_COPY);
      }

      if (data.OTHERS) {
        formData.append("OTHERS", data?.OTHERS);
      }

      if (data.PERMIT) {
        formData.append("PERMIT", data?.PERMIT); // Append PERMIT if available
      }

      if (data?.details) {
        Object.keys(data?.details).forEach((key) => {
          formData.append(`details[${key}]`, data.details[key]);
        });
      }
      const response = await dispatch(SalesMappingRequest(formData));
      if (response?.success) {
        setFormSubmitted(true); // Ensure Lottie page doesn't show
        reset();
      } else {
        setFormSubmitted(false); // Prevent Lottie from showing
      }
    } catch (error) {
      // Handle and log any error
      setFormSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: "100%",
        position: "fixed",
        borderRadius: "24px 24px 0px 0px ",
        overflow: "hidden",
      }}
    >
      <>
        {loading ? ( // Center the CircularProgress in the parent container
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh", // Adjust based on the available space
              width: "534px",
              backgroundColor: "white",
              borderRadius: "24px 24px 0px 0px ",
            }}
          >
            <CircularProgress />
          </Box>
        ) : formSubmitted ? (
          <Box
            sx={{
              textAlign: "center",
              borderRadius: "24px 24px 0px",
              backgroundColor: "white",
              marginTop: "20px",
              width: "515px",
              height: "425px",
            }}
          >
            <LottiePlayer width={"130px"} height={"130px"} image={verified} />
            <Typography
              component={"div"}
              sx={{
                marginTop: "60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginTop: "20px",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Mapping Request Raised Successfully!
              </Typography>

              <Button
                onClick={handleNavigateBtn}
                variant="contained"
                sx={{
                  textAlign: "center",
                  width: "183px",
                  height: "45px",
                  color: "white",
                  backgroundColor: "#0691FC",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Go To My Requests
              </Button>
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: "582px",
              padding: "0px",
              flexShrink: 0,
              backgroundColor: "white",
              height: "580px",
              borderRadius: "24px 24px 0px 0px ",
              boxShadow: 24,
              position: "relative",
              bottom: "0px",
              right: "20px",
              transition: "transform 0.5s ease-in-out",
              transform: open ? "translateY(0)" : "translateY(100%)",
            }}
            role="presentation"
          >
            <div
              className="raise-modal_header"
              style={{
                position: "sticky", // Make the header stick to the top
                top: 0, // Position at the top
                // Ensure it stays above the scrollable content
                // Background to prevent transparency when scrolling
              }}
            >
              {newQuoteRequestHeader?.map((item, index) => (
                <div
                  key={index}
                  role="button"
                  tabIndex={0}
                  className={`d-flex align-items-center justify-content-center flex-column header_items cursor_pointer
                ${activeHeader?.id === index ? "active_header_item" : ""}`}
                  onClick={() => handleHeaderChange(item)}
                >
                  <item.image />
                  <div
                    className={
                      activeHeader?.id === index
                        ? "active_veichle_name"
                        : "veichle_name"
                    }
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            <>
              <Box sx={{ overflowY: "auto", maxHeight: "600px" }}>
                <Typography
                  component={"div"}
                  sx={{
                    backgroundColor: "#FFEBDF",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      lineHeight: "19.5px",
                      color: "EF8C00",
                    }}
                  >
                    You are raising this request on behalf of the partner{" "}
                    {partnerId}
                  </Typography>
                </Typography>
                <form onSubmit={handleSubmit((data) => onSubmit(data, reset))}>
                  <Typography
                    component={"h2"}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "600",
                      color: "black",
                      margin: "17px ",
                    }}
                  >
                    Raise Policy Mapping
                  </Typography>
                  <Typography sx={{ marginTop: "15px", marginLeft: "20px" }}>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Vehicle is New"
                        clickable
                        icon={selectedChip === "new" ? <DoneIcon /> : null}
                        color={selectedChip === "new" ? "primary" : "default"}
                        onClick={() => handleChipClick("new")}
                      />
                      <Chip
                        label="Vehicle is Old"
                        clickable
                        icon={selectedChip === "old" ? <DoneIcon /> : null}
                        color={selectedChip === "old" ? "primary" : "default"}
                        variant={selectedChip === "old" ? "filled" : "outlined"}
                        onClick={() => handleChipClick("old")}
                      />
                    </Stack>
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "24px",
                      display: "flex",
                      width: "575px",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Controller
                      name="registrationNumber"
                      control={control}
                      render={({ field: { onChange, value, ...field } }) => (
                        <>
                          <TextInput
                            iconPosition="start"
                            icon={regIcon} // Example icon from MUI
                            {...field}
                            value={value || ""}
                            onChange={(e) => {
                              const alphanumericValue = e.target.value.replace(
                                /[^a-zA-Z0-9 ]/g,
                                "",
                              );
                              onChange(alphanumericValue);
                            }}
                            placeholder={
                              selectedChip === "old"
                                ? "Enter Registration Number"
                                : "Enter RTO Code"
                            }
                            style={{ width: "534px" }}
                            className="custom-text-input"
                          />
                          <Typography
                            sx={{ width: "534px", textAlign: "start" }}
                          >
                            {errors.registrationNumber && (
                              <Typography
                                component="span"
                                color="error"
                                sx={{ fontSize: "12px" }}
                              >
                                {errors.registrationNumber.message}
                              </Typography>
                            )}
                          </Typography>
                        </>
                      )}
                    />

                    {activeHeader.key === "BUS" && (
                      <>
                        <Controller
                          name="vehicleOwner"
                          control={control}
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <>
                              <TextInput
                                icon={busIcon}
                                iconPosition="start"
                                {...field}
                                value={value || ""}
                                onChange={(e) => {
                                  const alphabeticValue =
                                    e.target.value.replace(/[^a-zA-Z ]/g, "");
                                  onChange(alphabeticValue);
                                }}
                                placeholder="Enter Individual / School Name"
                                style={{
                                  width: "534px",
                                }}
                              />
                              <Typography
                                sx={{
                                  width: "534px",
                                  textAlign: "start",
                                }}
                              >
                                {errors.vehicleOwner && (
                                  <Typography
                                    component={"span"}
                                    color="error"
                                    sx={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.vehicleOwner.message}
                                  </Typography>
                                )}
                              </Typography>
                            </>
                          )}
                        />

                        <Controller
                          name="vehicleSubClass" // Ensure name matches validation schema
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <>
                              <DropDown
                                value={value} // Controlled value
                                onChange={(selectedOption) => {
                                  // Update value only if a valid option is selected
                                  if (selectedOption) {
                                    onChange(selectedOption);
                                  } else {
                                    onChange(null); // Set to null if no option is selected
                                  }
                                }}
                                onBlur={onBlur} // Handle blur events
                                placeholder="Select Vehicle Sub Class"
                                width="534px"
                                data={[
                                  "PASSENGER_BUS",
                                  "SCHOOL_BUS",
                                  "STAFF_BUS",
                                ]} // Ensure data is the correct options
                              />
                              <Typography
                                sx={{ width: "534px", textAlign: "start" }}
                              >
                                {errors.vehicleSubClass && (
                                  <Typography
                                    component="span"
                                    color="error"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    {errors.vehicleSubClass.message}
                                  </Typography>
                                )}
                              </Typography>
                            </>
                          )}
                        />
                      </>
                    )}

                    <Controller
                      name="policyNumber"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextInput
                            iconPosition="start"
                            icon={policyIcon} // Example icon from MUI
                            {...field}
                            placeholder="Enter Policy Number"
                            style={{ width: "534px" }}
                          />
                          <Typography
                            sx={{
                              width: "534px",
                              textAlign: "start",
                            }}
                          >
                            {errors.policyNumber && (
                              <Typography
                                component={"span"}
                                color="error"
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                {errors.policyNumber.message}
                              </Typography>
                            )}
                          </Typography>
                        </>
                      )}
                    />

                    <Controller
                      name="contactNumber"
                      control={control}
                      render={({ field }) => {
                        const handleInputChange = (e) => {
                          const newValue = e.target.value;
                          const Numeric = /^[0-9]*$/;

                          // Check if the input is alphanumeric and within the length limit
                          if (Numeric.test(newValue) && newValue.length <= 10) {
                            field.onChange(newValue); // Call field.onChange with the new value
                          }
                        };

                        return (
                          // Make sure to return the JSX
                          <>
                            <TextInput
                              {...field}
                              icon={phoneIcon}
                              iconPosition="start"
                              placeholder="Enter Partner's Number"
                              style={{ width: "534px" }}
                              onChange={handleInputChange} // Use the custom handler
                            />
                            <Typography
                              sx={{
                                width: "534px",
                                textAlign: "start",
                              }}
                            >
                              {errors.contactNumber && (
                                <Typography
                                  component={"span"}
                                  color="error"
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors.contactNumber.message}
                                </Typography>
                              )}
                            </Typography>
                          </>
                        );
                      }}
                    />

                    {/* upload sections */}
                    <Typography
                      component={"div"}
                      sx={{
                        marginTop: "10px",
                        width: "534px",
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{ border: "1px solid #E6E6E6 ", width: "209px" }}
                      ></Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                      >
                        Upload Docs
                      </Typography>

                      <Typography
                        component={"span"}
                        sx={{ border: "1px solid #E6E6E6 ", width: "209px" }}
                      ></Typography>
                    </Typography>
                    <Typography
                      component={"div"}
                      sx={{
                        width: "534px",
                        height: "42px",
                        borderRadius: "8px",
                        backgroundColor: "#FAFAFA",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 6px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontWeight: "500",
                        }}
                      >
                        Policy Copy{" "}
                        <Typography component={"span"} sx={{ color: "red" }}>
                          {" "}
                          *
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            color: "#727272",
                            lineHeight: "15px",
                            fontWeight: "400",
                            fontSize: "10px",
                          }}
                        >
                          only PDF
                        </Typography>
                      </Typography>
                      {/* file logic in here */}
                      <Controller
                        name="POLICY_COPY"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Box>
                              <Typography
                                component={"div"}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  document.getElementById("fileUpload").click()
                                }
                              >
                                {field.value && (
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "12px",
                                      marginTop: "0px",
                                      color: "#4F4F4F",
                                    }}
                                  >
                                    Selected file: {field.value.name}
                                  </Typography>
                                )}
                                <Typography component={"span"}>
                                  <img
                                    src={Upload}
                                    width={18}
                                    height={18}
                                    alt="upload"
                                  />
                                </Typography>
                                <Typography
                                  component={"span"}
                                  sx={{ color: "#0691FC", fontSize: "12px" }}
                                >
                                  Upload
                                </Typography>
                              </Typography>

                              {/* Hidden input for file upload */}
                              <input
                                type="file"
                                id="fileUpload"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const POLICY_COPY = e.target.files[0];
                                  field.onChange(POLICY_COPY); // Pass the file to React Hook Form's controller
                                }}
                              />
                            </Box>
                          </>
                        )}
                      />
                    </Typography>
                    {errors.POLICY_COPY && (
                      <Typography
                        component={"span"}
                        color="error"
                        sx={{
                          fontSize: "12px",
                          width: "500px",
                          textAlign: "start",
                        }}
                      >
                        {errors.POLICY_COPY.message}
                      </Typography>
                    )}

                    {/*other docs*/}
                    <Typography
                      component={"div"}
                      sx={{
                        width: "534px",
                        height: "42px",
                        borderRadius: "8px",
                        backgroundColor: "#FAFAFA",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 6px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontWeight: "500",
                        }}
                      >
                        Other Doc{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "#727272",
                            lineHeight: "15px",
                            fontWeight: "400",
                            fontSize: "10px",
                          }}
                        >
                          only pdf, jpg, jpeg, png
                        </Typography>
                      </Typography>
                      {/* file logic in here */}

                      <Controller
                        name="OTHERS"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Box>
                              <Typography
                                component={"div"}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("fileUpload_OTHERS")
                                    .click()
                                }
                              >
                                {field.value && (
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      fontSize: "12px",
                                      marginTop: "0px",
                                      color: "#4F4F4F",
                                    }}
                                  >
                                    Selected file: {field.value.name}
                                  </Typography>
                                )}
                                <Typography component={"span"}>
                                  <img
                                    src={Upload}
                                    width={18}
                                    height={18}
                                    alt="upload"
                                  />
                                </Typography>
                                <Typography
                                  component={"span"}
                                  sx={{ color: "#0691FC", fontSize: "12px" }}
                                >
                                  Upload
                                </Typography>
                              </Typography>

                              {/* Hidden input for file upload */}
                              <input
                                type="file"
                                id="fileUpload_OTHERS"
                                style={{ display: "none" }}
                                accept=".pdf, .jpeg, .jpg, .png"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    field.onChange(file); // Pass the file to React Hook Form's controller
                                  }
                                }}
                              />
                            </Box>
                          </>
                        )}
                      />
                    </Typography>

                    {/*Others-Document*/}
                    {activeHeader.key === "BUS" && (
                      <>
                        <Typography
                          component={"div"}
                          sx={{
                            width: "534px",
                            height: "42px",
                            borderRadius: "8px",
                            backgroundColor: "#FAFAFA",
                            marginTop: "6px",
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 6px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            component={"span"}
                            sx={{
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontWeight: "500",
                            }}
                          >
                            Permit Document{" "}
                            <Typography
                              component={"span"}
                              sx={{ color: "red" }}
                            >
                              {" "}
                              *
                            </Typography>
                            <Typography
                              component={"span"}
                              sx={{
                                color: "#727272",
                                lineHeight: "15px",
                                fontWeight: "400",
                                fontSize: "10px",
                              }}
                            >
                              only PDF
                            </Typography>
                          </Typography>
                          {/* file logic in here */}
                          <Controller
                            name="PERMIT"
                            control={control}
                            render={({ field }) => (
                              <>
                                <Box>
                                  <Typography
                                    component={"div"}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "3px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById("fileUpload-Permit")
                                        .click()
                                    }
                                  >
                                    {field.value && (
                                      <Typography
                                        component={"span"}
                                        sx={{
                                          fontSize: "12px",
                                          marginTop: "0px",
                                          color: "#4F4F4F",
                                        }}
                                      >
                                        Selected file: {field.value.name}
                                      </Typography>
                                    )}
                                    <Typography component={"span"}>
                                      <img
                                        src={Upload}
                                        width={18}
                                        height={18}
                                        alt="upload"
                                      />
                                    </Typography>
                                    <Typography
                                      component={"span"}
                                      sx={{
                                        color: "#0691FC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Upload
                                    </Typography>
                                  </Typography>

                                  {/* Hidden input for file upload */}
                                  <input
                                    type="file"
                                    id="fileUpload-Permit"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      const PERMIT = e.target.files[0];
                                      field.onChange(PERMIT); // Pass the file to React Hook Form's controller
                                    }}
                                  />
                                </Box>
                              </>
                            )}
                          />
                        </Typography>
                        {errors.PERMIT && (
                          <Typography
                            component={"span"}
                            color="error"
                            sx={{
                              fontSize: "12px",
                              width: "500px",
                              textAlign: "start",
                            }}
                          >
                            {errors.PERMIT.message}
                          </Typography>
                        )}
                      </>
                    )}

                    <Typography
                      component={"div"}
                      sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        background: "#fff",
                        padding: "14px 7px",
                        boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 1000,
                      }}
                    >
                      <Typography component={"div"}>
                        <Button
                          onClick={onClose}
                          variant="outlined"
                          color="error"
                          sx={{
                            color: "red",
                            width: "156px",
                            borderRadius: "8px",
                            textAlign: "center",
                            padding: "12px 48px 12px 48px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Typography>
                      <Typography component={"div"}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            backgroundColor: "#0691FC",
                            color: "white",
                            width: "156px",
                            borderRadius: "8px",
                            textAlign: "center",
                            padding: "12px 48px 12px 48px",
                          }}
                        >
                          Submit
                        </Button>
                      </Typography>
                    </Typography>

                    <Typography
                      component={"div"}
                      sx={{
                        height: "calc(40vh - 10px)",
                        overflowY: "auto",
                        paddingBottom: "100px",
                        width: "534px",
                      }}
                    ></Typography>
                  </Typography>
                </form>
              </Box>
            </>
          </Box>
        )}
      </>
    </Modal>
  );
}
