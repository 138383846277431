import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RaiseLogo from "../../assets/icons/policyraiselogo.svg";
import RaiseMapping from "../../assets/icons/policymapping.svg";
import { Typography } from "@mui/material";
import SelectPartnerModel from "./SelectPartnerModel";
import { useSelector } from "react-redux";
import { SALES_MAPPING, SALES_QUOTE } from "../../helpers/featuresName";
import QuoteRaiseIcon from "../../assets/icons/QuoteRaiseIcon.svg";

export default function RaiseMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTypeState, setModalTypeState] =
    React.useState("RAISE_NEW_REQUEST");
  const handleOpenModal = (modalType) => {
    setOpenModal(true);
    setModalTypeState(modalType);
    handleCloseMenu();
  };
  const handleCloseModal = () => setOpenModal(false);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  const raiseQuoteRequestPermission = permissions[SALES_QUOTE]?.ADD;
  const raiseMappingRequestPermission = permissions[SALES_MAPPING]?.ADD;

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#0691FC",
          color: "white",
          width: "220px",
          borderRadius: "8px",
          height: "48px",
          padding: "12px 16px",
          margin: "19px 27px",
          "&:hover": {
            backgroundColor: "#005BB5", // New hover color
          },
        }}
        id="demo-positioned-button"
        aria-controls={openMenu ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            gap: "5px",
          }}
        >
          <Typography component={"span"}>
            <img
              src={RaiseLogo}
              alt="raise-logo"
              style={{ width: "24px", height: "24px" }}
            />
          </Typography>
          Raise New Request
        </Typography>
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ top: "50px" }}
      >
        {raiseQuoteRequestPermission ? (
          <MenuItem
            sx={{
              width: "216px",
              borderRadius: "8px",
              padding: "8px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "500",
              color: "black",
              margin: "9px",
            }}
            onClick={() => handleOpenModal("RAISE_NEW_REQUEST")}
          >
            <img
              src={QuoteRaiseIcon}
              alt="raise-logo"
              style={{ width: "24px", height: "24px", margin: "5px" }}
            />
            New Quote Request
          </MenuItem>
        ) : (
          ""
        )}
        {raiseMappingRequestPermission ? (
          <MenuItem
            sx={{
              width: "216px",
              borderRadius: "8px",
              padding: "8px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "500",
              color: "black",
              margin: "9px",
            }}
            onClick={() => handleOpenModal("RAISE_POLICY_MAPPING")}
          >
            <img
              src={RaiseMapping}
              alt="raise-logo"
              style={{ width: "24px", height: "24px", margin: "5px" }}
            />
            Raise Policy Mapping
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <SelectPartnerModel
        openModal={openModal}
        handleClose={handleCloseModal}
        modalType={modalTypeState}
      />
    </div>
  );
}
