import { Box, CircularProgress, Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import TableComponent from "../../components/Table/QuoteTable";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuoteListAPI } from "../../store/action/quote";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { debounce, formatDate } from "../../utils/utils";
import Paginations from "../../components/generic/Pagination/Pagination.jsx";
import CustomTabing from "../../components/generic/Tabing/CustomTabing";


export default function QuoteRequest() {
  let searchParams = {};
  const dispatch = useDispatch();
  const quoteList = useSelector((state) => state.quote?.quoteList);
  const pagination = useSelector((state) => state.quote?.pagination);
  const quoteBucket = useSelector((state) => state.quote?.quoteBucket);
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [product, setProduct] = useState("");
  const isLoading=useSelector((state) => state.quote?.isLoading);


  // let token = window.localStorage.getItem("token");
  const [search, setSearch] = useState("");
  // const isLoading = useSelector((state) => state.quote.quoteList);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  
   // Debounce the search input
   const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch("");
      }
    }, 300),
    [],
  );

  //HandleSearch 
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };
  //Tab change 
  const handleTabChange = (newValue) => {
    if (!isLoading) {
      setcurrentBucket(newValue);
      setCurrentPage(1);
    }
  };

   // page change
   const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  
  useEffect(() => {
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }
    if (product) {
      searchParams["product"] = product;
    }

    dispatch(getQuoteListAPI(searchParams));
  }, [
    currentBucket,
    currentPage,
    filterByDate,
    debouncedSearch,
    product,
  ]);

 

  return (
    <main className="main">
      <Header title="Quote Requests" />
      <header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Search
              value={search}
              onChange={handleSearch}
              placeholder={"Partner name, Partner code, Ticket ID"}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label">Filters :</label>
              <DropDown
              value={product}
              onChange={(e) =>
                setProduct(
                  e.target.value === "No Product" ? "" : e.target.value,
                )
              }
              width={145}
              placeholder="Product"
              data={["CAR", "TW", "GCV", "PCV", "BUS", "MISC_D", "No Product"]}
            />
             
              <div className="date-picker">
                <Flatpickr
                  placeholder="Date Range"
                  options={{
                    dateFormat: "d-m-Y",
                    maxDate: new Date(),
                    mode: "range",
                  }}
                  className="flat_picker_date"
                  style={{ width: "200px", height: "41px" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </Stack>
        </header>

        <CustomTabing
        tabList={quoteBucket?.bucketList || []}
        value={quoteBucket?.curBucket}
        handleChange={handleTabChange}
      />
      <div className="list-wrapper">    
      {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableComponent
            rows={quoteList}
          />
        )}
      </div>
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </main>
  );
}
