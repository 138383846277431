import React from "react";
import "./index.css";
import Stack from "@mui/material/Stack";
import TextInput from "../Input";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

const Search = ({ value, onChange, placeholder, ...rest }) => {

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    
    const alphaNumeric = /^[a-zA-Z0-9 ]*$/;
    
    if (alphaNumeric.test(newValue) && newValue.length <= 12) {
      onChange(e);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextInput
        style={{ width: 344 }}
        placeholder={placeholder ? placeholder : "Partner's Name"}
        value={value}
        onChange={handleInputChange}  
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ mt: -0.2 }} />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Stack>
  );
};

export default Search;
