import Box from "@mui/material/Box";
import { PieChart } from "@mui/x-charts/PieChart";
import { Typography } from "@mui/material";
import { Colors } from "../../../assets/constant.js";

export default function DashboardChart({ onboardingDetails }) {
  const colorsArray = Object.values(Colors);
  const chartData = onboardingDetails?.bucketList?.map((bucketData, i) => {
    return {
      label: bucketData?.bucketName,
      value: bucketData.count,
      color: colorsArray[i % colorsArray.length],
    };
  });
  const total = onboardingDetails?.total;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "20px",
        width: "370px",
        height: "370px",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "21px",
          textAlign: "center",
          marginTop: "14px",
          padding: "10px",
        }}
      >
        Partners Onboarding
      </Typography>
      <Box
        sx={{
          // width: "370px",
          display: "flex",
          justifyContent: "center ",
          // height: "360px",
          padding: "0px 0px",
          position: "relative",
        }}
      >
        <Typography
          component={"div"}
          sx={{
            width: "370px",
            display: "flex",
            justifyContent: "center ",
            height: "401px",
            padding: "0px 37px",
            position: "absolute",
            top: "-70px",
            left: "15px",
          }}
        >
          <PieChart
            sx={{ position: "relative" }}
            height={370}
            width={550}
            series={[
              {
                data: chartData,
                innerRadius: 110,
                colorByPoint: true,
                arcLabel: (params) => {
                  return params.value && params.value > 0 ? params.value : "";
                },
              },
            ]}
            skipAnimation={false}
            // Legend Customization
            legend={{
              direction: "row", // Stack items vertically
              itemGap: 22, // Gap between items
              itemMarkHeight: 20, // Height of the legend mark
              itemMarkWidth: 20, // Width of the legend mark
              labelStyle: {
                fontSize: "14px", // Customize label font size
                fontWeight: "600", // Customize label font weight
                color: "#333", // Label color
              },
              markGap: 10, // Gap between the mark and label

              padding: { top: 0, bottom: -170, left: 0, right: 10 }, // Padding around the legend
              margin: { bottom: 40, left: 0, right: 0, top: 0 }, // Adding margins for the legend
              position: {
                horizontal: "middle", // Horizontal position of legend ('left', 'middle', 'right')
                vertical: "bottom", // Vertical position ('top', 'middle', 'bottom')
              },
            }}
          />
          <Typography
            component={"div"}
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              top: "159px",
              left: "139px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "21px",
                textAlign: "center",
                letterSpacing: "0.22px",
                color: "#848A9C",
              }}
            >
              Total
            </Typography>

            <Typography
              component={"span"}
              sx={{
                fontWeight: "700",
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0.28px",
                color: "black",
              }}
            >
              {" "}
              {total}{" "}
            </Typography>
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}
