import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function DashboardToggle({ value, onChange }) {
  return (
    <div>
      <ToggleButtonGroup
        color="warning"
        exclusive
        value={value} // Use the value passed from props
        onChange={onChange} // Directly handle the change
        aria-label="Platform"
        sx={{
          backgroundColor: "black",
          color: "white",
          borderRadius: "15px",
        }}
      >
        <ToggleButton
          value="MAPPING"
          sx={{
            borderRadius: "15px",
            textTransform: "none",
            color: value === "MAPPING" ? "white" : "black", // Text color based on value
            backgroundColor: value === "MAPPING" ? "black" : "white", // Background color
            "&.Mui-selected": {
              backgroundColor: "black", // Background color when selected
              color: "white", // Text color when selected
            },
            "&:hover": {
              color: "white", // Text color on hover
            },
          }}
        >
          Mapping Date
        </ToggleButton>
        <ToggleButton
          value="ISSUANCE"
          sx={{
            borderRadius: "15px",
            textTransform: "none",
            color: value === "ISSUANCE" ? "white" : "black", // Text color based on value
            backgroundColor: value === "ISSUANCE" ? "black" : "white", // Background color
            "&.Mui-selected": {
              color: "white", // Text color when selected
            },
            "&:hover": {
              color: "white", // Text color on hover
            },
          }}
        >
          Issuance Date
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
