const inititalState = {
  insurerList: [],
  addOnsList: [],
};

export default function insurerReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_INSURER_LIST":
      return {
        ...state,
        insurerList: action.data.insurerList,
        addOnsList: action.data.addOnsList,
      };
    default:
      return state;
  }
}
